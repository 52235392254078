<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>스포츠 배팅내역</sub-title>
                <div class="sports_bet_list">
                    <bet-info-links></bet-info-links>

                    <!--배팅내역 리스트-->
                    <sports-bet-list-comp
                            :bet-list="betList"
                            @cancelOrDelBet="cancelOrDelBet"
                            @betInfoChecked="betInfoChecked"
                            :position="1"></sports-bet-list-comp>

                </div>

                <pagination :page-index="pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"></pagination>
                <div class="board_list">
                  <!--배팅내역에서 게시글작성-->
                  <board-writer
                          :bet-ids="selectedBetIds"
                          :from="1"
                          :btype="sportsConst.GAME_PROTO" btn-text="선택내역 게시판 등록"></board-writer>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
    </div>
</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import Pagination from "../../components/pagenation/Pagination";
    import RightBarComp from "../../components/RightBarComp";
    import sportsConst from "../../common/sportsConst";
    import {getBetList} from "../../network/sportsBetRequest";
    import {fix2, kindText} from "../../common/SportsUtils";
    import SportsBetListComp from "../../components/sports/SportsBetListComp";
    import BoardWriter from "../../components/BoardWriter";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import BetInfoLinks from "../../components/BetInfoLinks";

    export default {
        name: "SportsBetInfo",
        mixins:[postionMixin],
        components: {
            BetInfoLinks,
            RightButtonsComp,
            UserInfoComp,
            SportsBetCartComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp,
            TopbarComp,
            LeisureLeftComp, LeftBarComp, BoardWriter, SportsBetListComp, SubTitle, Pagination, RightBarComp
        },
        data() {
            return {
                sportsConst,
                pageNum: 1,
                pageSize: 5,
                total: 1,
                orderBy: 'b.id DESC',
                search: {
                    betResult: null
                },
                selectedBetIds: [],
                betList: [],
                position:"S배팅내역",
            }
        },
        methods: {
            /*전체,당첨,낙첨,..버튼 클릭시*/
            changeBetResult(betResult) {
                this.pageNum = 1
                this.search.betResult = betResult
                this.initBetList()
            },
            /*SportsBetListComp.vue에서 취소 또는 삭제시 배팅내역 새로고침*/
            cancelOrDelBet() {
                this.initBetList()
            },
            /*SportsBetListComp.vue에서 체크박스 클릭시*/
            betInfoChecked(betIds) {
                this.selectedBetIds = betIds
            },
            initBetList() {
                this.$store.commit(RECEIVE_SHOW_LOADING)

                getBetList(this.search, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    if (res.data.success) {
                        this.total = res.data.total
                        this.betList = res.data.data;
                        this.betList.forEach((betInfo) => {
                            betInfo.betInfoGameList.forEach((betInfoGame) => {
                                try {
                                    //배당 소수점 두자리 설정 1.8 => 1.80
                                    fix2(betInfoGame)
                                    //승무패,핸디오언 텍스트 설정
                                    kindText(betInfoGame.game)
                                } catch (e) {

                                }
                            })
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            pageChange(page) {
                this.pageNum = page
                this.initBetList()
            }
        },
        created() {
            this.initBetList()
        }
    }
</script>
<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
</style>